@import "../../custom.scss";

.footerContainer {
    padding: 1% 3%;
    background-color: $primary;

    .footerLanguagesContainer {
        display: flex;

        .footerLanguage {
            margin-bottom: 0;
            margin-left: 8px;
            padding-right: 16px;
            color: white;
            cursor: pointer;
            letter-spacing: 0.05em;
        }

        .activeLanguage {
            font-weight: bold;
            margin-bottom: 0;
            margin-left: 8px;
            padding-right: 16px;
            color: white;
            cursor: pointer;
            letter-spacing: 0.05em;

        }
    }

    .footerSocialMediaContainer {
        display: flex;

        p {
            margin: 0;
            font-size: 16px;
            padding-right: 16px;
            color: white;
            letter-spacing: 0.06em;
        }

        img {
            padding: 4px;
        }
    }
}

@media screen and (max-width:500px) {
    .footerSocialMediaContainer p {
        display: none;
    }
}