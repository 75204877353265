@font-face {
    font-family: Helvetica;
    src: url('./assets/fonts/Helvetica.ttf');
}

* {
    font-family: Helvetica;
}
body{
    overflow-x: hidden;
}