@import "../../custom.scss";

.ServiceWrapper {
    // margin-top: 2%;
    // margin-bottom: 2%;
    color: $offWhite;
    width: 100%;
    height: 30%;
    font-weight: 400;
    font-size: 27px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.07em;
    position: sticky;
    padding: 0 !important;
}

.imgContainerService {
    width: 100%;
    height: 80%;
    margin-bottom: 24px;
}

.imgService {
    width: 100%;
    height: 50vh;
    position: relative;
    display: flex;
}

.imgServiceMob {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
}

.overlay {
    // width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    // right: -5%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 24px;
}

.paragService {
    width: 50%;
    padding-top: 1.5%;
    font-size: 16px;
}

.seperatingFrower {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 48px;
}


.sep {
    border: 1.5px solid $primary;
    width: 8%;
    height: 0px;
    display: inline-block;
}

.flowerSep {
    width: 2.5%;
    height: 2.5%;
    display: inline-block;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.g {
    width: 100%;
    height: 100%;
}


@media only screen and (max-width: 1000px) {
    .ServiceWrapper {
        font-weight: 200;
        font-size: 20px;
        line-height: 20px;
    }

    .paragService {
        width: 75%;
        font-size: 14px;
    }
}

@media screen and (max-width:800px) {
    .nameService {
        font-size: 18px;
    }

    .paragService {
        font-size: 12px;
    }

}



@media only screen and (max-width: 600px) {
    .imgService {
        display: none;
    }

    .imgServiceMob {
        display: flex;
        height: 60vh !important;
    }

    .ServiceWrapper {
        font-weight: 200;
        font-size: 16px;
        line-height: 15px;
    }

    .paragService {
        width: 75%;
        // font-size: 14px;
    }
}