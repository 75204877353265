@import "../../custom.scss";

.contactUsWrapper {
    color: $offWhite;
    width: 100%;
    height: auto !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: justify;
    letter-spacing: 0.06em;
    position: sticky;
    line-height: 20px;
}

.imgContainerContactUs {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgContactUs {
    width: 100%;
    height: 100vh;
    // position:relative;
    display: flex;
}

.imgContactUsMob {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
}

.overlay3 {
    position: absolute;
    background-color: $whiteContact;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap !important;
    max-width: 600px;

}

.contactAndLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 16px 32px;
    width: 200px;
    height: 20%;
}

.contactLogo {
    width: 60px;
    margin: auto;
    padding: 10px;
}

.nameLogo {
    width: auto;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
        margin: 0;
    }
}

.imgLogo {
    width: 90%;
    height: 90%;
}

@media screen and (max-width:600px) {
    .nameLogo {
        font-size: 10px;
        text-align: center;
    }

    .overlay3 {
        width: 80%;
    }

    .contactAndLink {
        margin-inline: 0px;
    }

    .imgContactUs {
        display: none;
    }

    .imgContactUsMob {
        display: flex;
        height: 100vh;
    }

}