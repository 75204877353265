@import "../../custom.scss";

.Join {
    text-align: center;
    background-color: $backgroundgrey;
    margin-top: 32px;
    padding-top: 2%;
    padding-bottom: 2%;
    letter-spacing: 0.06em;
    font-weight: 700;
}

.seperator {
    border: 2px solid $primary;
    width: 30%;
    margin: auto;
    height: 0px;
}

.text1 {
    margin: 0%;
    padding-bottom: 8px;
    color: $darkgrey;
    font-size: 19.5px;
    line-height: 24px;
}

.text2 {
    margin: 0%;
    padding-top: 8px;
    color: $lightgrey;
    font-size: 18px;
    line-height: 22px;
}

@media screen and (max-width:500px) {


    .text1 {
        font-size: 18px;
        line-height: 20px;
    }

    .text2 {
        font-size: 14px;
        line-height: 16px;
    }

}