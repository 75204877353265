.carousel-cell {
  width: 40%;
  height: 50vh;
}

.carousel-cell img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width:600px) {
  .carousel-cell {
    height: 40vh;
  }
}

@media screen and (max-width:450px) {
  .carousel-cell {
    height: 30vh;
  }
}