@import "../../custom.scss";

.breifContainer {
    margin: 5% 0%;

    .right,
    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
    }

    .right {
        border-right: 4px solid $primary ;
    }

    .left {
        border-left: 4px solid $primary ;
    }

    .f {
        width: 171px;
        height: 171px;
    }

    .bigText {
        font-weight: 700;
        line-height: 45px;
        text-align: left;


        .text3 {
            margin: 0%;
            color: $darkgrey;
            font-size: 20px;
            letter-spacing: 0.28em;
        }

        .text4 {
            padding-top: 16px;
            margin: 0%;
            color: $lightgrey;
            font-size: 20px;
            letter-spacing: 0.01em;
        }
    }
}



@media screen and (max-width:1300px) {
    .text3 {
        font-size: 18px !important;
        letter-spacing: 0.15em !important;
    }

    .text4 {
        font-size: 18px !important;
    }

    .bigText {
        line-height: 40px !important;
    }
}

@media only screen and (max-width: 992px) {

    .right {
        border-right: none !important;
        border-bottom: 5px solid $primary;
    }

    .left {
        border-left: none !important;
        border-top: 5px solid $primary;
    }

    .bigText {
        padding: 32px 0px;
        text-align: center !important;


        .text3 {
            font-size: 22px !important;
        }

        .text4 {
            font-size: 20px !important;
        }
    }
}


@media screen and (max-width:600px) {
    .bigText {
        .text3 {
            font-size: 18px !important;
        }

        .text4 {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width:330px) {
    .bigText {
        .text3 {
            font-size: 16px !important;
        }

        .text4 {
            font-size: 14px !important;
        }
    }
}