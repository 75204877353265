@import "../../custom.scss";

.aboutWrapper {
    color: $darkgrey;
    width: 100%;
    font-weight: 700;
    font-size: 25px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.06em;
    position: sticky;
    line-height: 34px;
}

.imgContainerAbout {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgAbout {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
}

.imgAboutMob {
    width: 100%;
    height: 100%;
    position: relative;
    display: none;
}

.overlay2 {
    padding-bottom: 8px;
    width: 50%;
    height: auto;
    position: absolute;
    background-color: $whiteAbout;
}

.aboutText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.nameAbout {
    width: 90%;
    margin: 1%;
    margin-bottom: 4%;
}

.paragAbout {
    width: 90%;
    font-size: 16px;
}

.twoflowers {
    width: 100%;
    height: 14%;
    position: sticky;
    margin-top: 1.5%;
}

.miniFlowerRight {
    width: 10%;
    height: 100%;
    margin-left: 1%;
    display: inline-block;
}

.miniFlowerLeft {
    width: 10%;
    height: 100%;
    margin-left: 78%;
    display: inline-block;
}

@media only screen and (max-width: 1000px) {
    .aboutWrapper {
        font-size: 20px;
        line-height: 25px;
    }

    .paragAbout {
        font-size: 15px;
    }

    .twoflowers {
        height: 10%;
    }

}

@media only screen and (max-width: 850px) {
    .overlay2 {
        width: 75%;
    }

    .aboutWrapper {
        font-size: 20px;
        line-height: 20px;
    }

    .twoflowers {
        height: 8%;
    }

    .paragAbout {
        font-size: 15px;
        line-height: 20px;
    }

    .aboutText {
        padding-top: 8px;
    }
}


@media only screen and (max-width: 650px) {

    .imgAbout {
        display: none;
    }

    .imgAboutMob {
        display: flex;
        height: 100vh;
    }

    .aboutWrapper {
        font-size: 16px;
        line-height: 20px;
    }

    .paragAbout {
        font-size: 12px;
        line-height: 20px;
    }

}

@media only screen and (max-width: 310px) {
    .overlay2 {
        width: 90%;
    }

    .aboutWrapper {
        font-size: 17px;
        line-height: 20px;
    }
}