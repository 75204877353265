@import "../../custom.scss";

.OffersBigBox {
    margin: auto;
    text-align: center;
    background-color: $backgroundgrey;
    margin-top: 2%;
    margin-bottom: 7%;
    padding-top: 4%;
    padding-left: 20%;
    padding-bottom: 5%;
    padding-right: 17%;
    font-weight: 700;
    letter-spacing: 0.06em;
    color: $darkgrey;
}

.weofferContainer {
    padding-bottom: 10%;
}

.weOffer {
    font-size: 27.5px;
    line-height: 32px;
}

.seperatorOffer {
    border: 2px solid $primary;
    width: 40%;
    margin: auto;
    height: 0px;
}

.offerMom {
    width: 50%;
    height: 50%;
    margin: auto;
}

.imgContainerOffer {
    width: 100%;
    height: 100%;
    margin-top: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nameOffer {
    margin-top: 16px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
}