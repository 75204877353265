@import '../../custom.scss';

.navbarWrapper {
  padding: 16px;
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.bookNowButton {
  background-color: $primary !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 4px 32px !important;
  border-radius: 1px !important;
  height: 35px !important;

}

.bookNowButton:hover {
  background-color: white !important;
  border: 2px solid $primary !important;
  color: $primary !important;
  padding: 4px 32px !important;
  height: 35px !important;
  border-radius: 0 !important;

}

.navLinks,
.navLinks:hover {
  color: black;
  text-decoration: none;
}

.navLinks-active {
  color: black;
  font-weight: bold;
}

.navLinks-active::after {
  content: '' !important;
  display: block !important;
  border-bottom: 3px solid $primary !important;
  padding-top: 8px !important;
}