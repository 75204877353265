@import "../../custom.scss";

.modalHeader {
    border-bottom: 0px !important;
}

.btn-close {
    opacity: 1 !important;
    background: rgba($color: #222222, $alpha: 0.2) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.8em auto no-repeat !important;
    border-radius: 100px !important;
}

.twoDiv {
    text-align: center;
    letter-spacing: 0.06em;
    font-weight: 700;
    font-size: 20.5px;
    line-height: 24px;
    display: inline-block;
    color: $or;
    width: 40%;
    height: auto;
}

.lightDiv {
    color: $darkgrey;
    font-size: 27.5px;
    line-height: 32px;
    margin: 3%;
    margin-top: 0%;
}

.darkDiv {
    color: $offWhite;
    background-color: $primary;
    font-weight: 400;
    font-size: 14px;
    line-height: 29px;
    padding: 3%;
    margin: 3%;
    margin-bottom: 5%;
}

.lightDarkContainer {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width:992px) {
    .modal-dialog {
        max-width: 90% !important;
    }
}

@media only screen and (max-width: 800px) {
    .lightDarkContainer {
        width: 100% !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .modal-dialog {
        max-width: 55% !important;
    }

    .twoDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 576px) {

    .modal-dialog {
        max-width: 100% !important;
    }

}

@media screen and (max-width:400px) {
    .lightDiv {
        font-size: 22px;
    }

    .darkDiv {
        font-size: 14px;
    }
}